<template>
  <div
    ref="container"
    class="data-[button-style='button-normal']:basis-32 md:data-[button-style='button-responsive-compact']:basis-32 md:data-[button-style='panel-button-normal']:basis-32 md:data-[button-style='panel-button-responsive-compact']:basis-32"
    :data-button-style="buttonStyle"
    @keydown.esc="closeDisclosureWithFocusOnButton"
  >
    <ControlButton
      :id="id"
      ref="disclosureButton"
      type="button"
      :class="buttonClass"
      :inverted="inverted"
      :button-style="buttonStyle"
      :aria-expanded="disclosureVisible"
      :aria-controls="`${id}_content_container`"
      :tabindex="tabindex"
      :aria-label="ariaLabel"
      @click="toggleDisclosure"
    >
      <template v-if="$slots.icon" #icon>
        <slot name="icon"></slot>
      </template>
      <slot></slot>
    </ControlButton>
    <div
      v-show="disclosureVisible"
      :id="`${id}_content_container`"
      ref="disclosure"
      class="rounded border border-primary-1 bg-base-2 shadow-md"
      @keydown.left.prevent="focusPreviousElementInDisclosure()"
      @keydown.up.prevent="focusPreviousElementInDisclosure()"
      @keydown.right.prevent="focusNextElementInDisclosure()"
      @keydown.down.prevent="focusNextElementInDisclosure()"
      @keydown.home.prevent="focusFirstElementInDisclosure()"
      @keydown.end.prevent="focusLastElementInDisclosure()"
      @keydown.tab.exact="closeDisclosureIfLastElementIsInFocus()"
    >
      <ul v-if="$slots.disclosureOptions">
        <slot name="disclosureOptions"></slot>
      </ul>
      <slot v-else-if="$slots.disclosureContent" name="disclosureContent">
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { provide, ref } from "vue";

import { tabbable } from "tabbable";
import { disclosureCloseKey } from "./disclosureClose";
import { usePopup } from "../composables/usePopup";
import type { ButtonStyle } from "../types/buttonStyle";
import ControlButton from "./ControlButton.vue";

interface Props {
  id: string;
  inverted?: boolean;
  alwaysFull?: boolean;
  tabbableDisplayCheckType?: "full" | "none";
  buttonStyle?: ButtonStyle;
  popupOffset?: [number, number];
  tabindex?: number;
  buttonClass?: string;
  ariaLabel?: string;
}
const props = withDefaults(defineProps<Props>(), {
  inverted: true,
  alwaysFull: false,
  type: "button",
  tabbableDisplayCheckType: "full",
  buttonStyle: "panel-button-normal",
  popupOffset: () => [0.25, 0.25],
  tabindex: 0,
  buttonClass: "",
  ariaLabel: undefined,
});

provide(disclosureCloseKey, disclosureAction);

function disclosureAction() {
  disclosureVisible.value = false;
  disclosureButton.value?.focus();
}
function focus() {
  disclosureButton.value?.focus();
}

const disclosureVisible = ref<boolean>(false);
const container = ref<HTMLElement>();
const disclosure = ref<HTMLElement>();
const disclosureButton = ref<HTMLElement>();
const popup = usePopup(
  disclosure,
  container,
  disclosureVisible,
  props.popupOffset,
  "fixed",
  false,
  "top-start",
);

onClickOutside(container, () => {
  disclosureVisible.value = false;
});

function getTabbableDisclosureElementsFocusIndex() {
  if (disclosure.value) {
    const tabbableElements = tabbable(disclosure.value, {
      displayCheck: props.tabbableDisplayCheckType,
    });
    for (let i = 0; tabbableElements.length; i++) {
      if (document.activeElement === tabbableElements[i]) {
        return { focusedIndex: i, tabbableElements };
      }
    }
  }
  return { focusedIndex: undefined, tabbableElements: undefined };
}

function closeDisclosureIfLastElementIsInFocus() {
  const { focusedIndex, tabbableElements } =
    getTabbableDisclosureElementsFocusIndex();
  if (tabbableElements && focusedIndex === tabbableElements.length - 1) {
    disclosureVisible.value = false;
  }
}

function focusNextElementInDisclosure() {
  const { focusedIndex, tabbableElements } =
    getTabbableDisclosureElementsFocusIndex();
  if (
    tabbableElements &&
    tabbableElements.length > 0 &&
    focusedIndex !== undefined
  ) {
    tabbableElements[(focusedIndex + 1) % tabbableElements.length].focus();
  }
}

function focusPreviousElementInDisclosure() {
  const { focusedIndex, tabbableElements } =
    getTabbableDisclosureElementsFocusIndex();
  if (
    tabbableElements &&
    tabbableElements.length > 0 &&
    focusedIndex !== undefined
  ) {
    const previousIndex =
      focusedIndex <= 0 ? tabbableElements.length - 1 : focusedIndex - 1;
    tabbableElements[previousIndex].focus();
  }
}

function focusFirstElementInDisclosure() {
  if (disclosure.value) {
    const tabbableElements = tabbable(disclosure.value, {
      displayCheck: props.tabbableDisplayCheckType,
    });
    if (tabbableElements && tabbableElements.length > 0) {
      tabbableElements[0].focus();
    }
  }
}

function focusLastElementInDisclosure() {
  if (disclosure.value) {
    const tabbableElements = tabbable(disclosure.value, {
      displayCheck: props.tabbableDisplayCheckType,
    });
    if (tabbableElements && tabbableElements.length > 0) {
      tabbableElements[tabbableElements.length - 1].focus();
    }
  }
}

function toggleDisclosure() {
  disclosureVisible.value = !disclosureVisible.value;
  popup.update();
}

function closeDisclosureWithFocusOnButton() {
  disclosureVisible.value = false;
  if (disclosureButton.value) {
    disclosureButton.value.focus();
  }
}
defineExpose({
  focus,
});
</script>
